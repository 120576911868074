import { autorun, observable } from "mobx";
import settings from "../data/Settings.json";
import axios from "axios";

class AuthStore {
  @observable token = null;
  @observable user = null;
  @observable currentUser = null;

  constructor() {
    this.multipartHeader = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };

    if (env == "production") {
      this.enplugAPI = settings.enplugAPI;
    } else {
      this.enplugAPI = settings.enplugDevAPI;
    }
  }

  checkToken = () => {
    this.token = localStorage.getItem("access_token");
    this.user = {
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email")
    };
  }

  login = (values) => {
    const formData = this.getFormData(values);

    return axios.post(this.enplugAPI + "/token", formData, this.multipartHeader)
    .then(response => {
      this.token = response.data.access_token;
      this.user = response.data.user;
      this.setCoockies(this.user);
    });
  }

  registration = (values) => {
    const formData = this.getFormData(values);

    return axios.post(this.enplugAPI + "/register", formData, this.multipartHeader)
    .then(response => {
      this.token = response.data.access_token;
      this.user = response.data.user;
      this.setCoockies(this.user);
    });
  }

  resetPassword = (values) => {
    const formData = this.getFormData(values);
    return axios.post(this.enplugAPI + "/reset_password", formData, this.multipartHeader);
  }

  logout = () => {
    return fetch(this.enplugAPI + "/logout", {
      method: "POST",
      headers: new Headers({
        'Authorization': this.token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
    .then(response => {
      this.token = null;
      this.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      return response;
    });
  }

  setCoockies = (user) => {
    localStorage.setItem("access_token", this.token);
    localStorage.setItem("name", user.name);
    localStorage.setItem("email", user.email);
  }

  // Other
  getFormData = (data) => {
    let formData = new FormData();

    for ( const key in data ) {
      if (!Array.isArray(data[key])) {
        formData.append(key, data[key]);
      } else {
        data[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
    }

    return formData;
  }
}

const store = window.AuthStore = new AuthStore;

export default store;

autorun(() => {
  console.log(store);
});