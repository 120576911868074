import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import App from './components/App.jsx';
import stores from './stores/index';

const rootEl = document.getElementById('app');

const renderComponent = Component => {
  const browserHistory = require('history').createBrowserHistory();
  const routingStore = new RouterStore();
  const history = syncHistoryWithStore(browserHistory, routingStore);
  window.appHistory = syncHistoryWithStore(browserHistory, routingStore);

  ReactDOM.render(
    <div>
      <Router history={history}>
        <Provider {...stores}>
          <Component />
        </Provider>
      </Router>
    </div>,
    rootEl,
  );
};

renderComponent(App);
