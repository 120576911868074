import { autorun, observable, toJS } from "mobx";
import axios from "axios";
import qs from "qs";
import settings from "../data/Settings.json";

class UserStore {
  @observable user = null;
  @observable agencyList = null;
  @observable userSettings = null;

  constructor() {
    if (env == "production") {
      this.apiUrl = `${settings.enplugAPI}/user`;
    } else {
      this.apiUrl = `${settings.enplugDevAPI}/user`;
    }
  }

  setUser = (values, token) => {
    const params = qs.stringify(values);
    return fetch(this.apiUrl, {
      method: "POST",
      headers: new Headers({
        'Authorization': token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: params
    })
    .then(response => response.json())
    .then(data => {
      this.user = data;
      this.prepareSettings();
      return data;
    });
  }

  getUser = (token) => {
    return fetch(this.apiUrl, {
      method: "GET",
      headers: new Headers({
        'Authorization': token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
    .then(response => response.json())
    .then(this.updateUser);
  }

  editUser = (user, token) => {
    const params = qs.stringify(user);
    return fetch(this.apiUrl, {
      method: "POST",
      headers: new Headers({
        'Authorization': token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: params
    })
    .then(response => response.json())
    .then(this.updateUser);
  }

  updateUser = (data) => {
    this.user = data;
    this.prepareSettings();
    return this.user;
  } 

  getCurrentUser = () => {
    return toJS(this.user);
  }

  setAgency = (agency, token) => {
    let user = this.getCurrentUser();
    user.agency_contact = agency.rentalTelephone;
    user.agency_id = agency.id;
    user.agency_name = agency.name;

    return this.setUser(user, token)
    .catch(error => console.log(error));
  }

  searchAgency = (value, token) => {
    const data = {
      headers: {"Authorization" : `Bearer ${token}`}
    }

    return axios.get(
      `https://api.domain.com.au/v1/agencies?q=${value}`,
        data
    )
    .then(response => {
      this.agencyList = response.data;
      return response;
    });
  }

  prepareSettings = () => {
    this.userSettings = {
      for_sale_background: this.user.for_sale_background ? this.user.for_sale_background : "#E4002B",
      for_sale_text_color: this.user.for_sale_text_color ? this.user.for_sale_text_color : "#ffffff",
      lightbg_background: this.user.lightbg_background ? this.user.lightbg_background : "#ffffff",
      lightbg_text_color: this.user.lightbg_text_color ? this.user.lightbg_text_color : "#1A2E5A",
      darkbg_background: this.user.darkbg_background ? this.user.darkbg_background : "#192e5b",
      darkbg_text_color: this.user.darkbg_text_color ? this.user.darkbg_text_color : "#ffffff",
      is_qr: this.user.is_qr ? this.user.is_qr : ""
    };
  }
}

const store = window.UserStore = new UserStore;

export default store;

autorun(() => {
    console.log(store);
});