import { RouterStore } from 'mobx-react-router';
import NavigationStore from './NavigationStore';
import ListingStore from './ListingStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';

const RoutingStore = new RouterStore();
const stores = {
  AuthStore,
  RoutingStore,
  NavigationStore,
  ListingStore,
  UserStore
};

export default stores;
