import { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import logotype from 'images/logotype.png';

@inject('AuthStore', 'UserStore', 'ListingStore', 'NavigationStore')
@observer
@withRouter
export default class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userMenu: false
		}
	}

	render() {
    const { AuthStore, NavigationStore } = this.props;

		return (
			<nav className="navbar navbar-expand-lg">
				<div className="container">
					<Link className="navbar-brand" to="/">
						<img src={logotype} />
					</Link>
					<div id="navbarNav"
						onClick={e => this.toggleUserMenu(e, false)}
					>
						<ul className="navbar-nav">							
              <li className="nav-item">
                <Link className={`nav-link btn ${this.checkLink("available-listings") ? "btn-primary" : "btn-white" }`}
                  to="/available-listings"
                >
                  Available Listings
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link btn ${this.checkLink("active-listings") ? "btn-primary" : "btn-white" }`}
                  to="/active-listings"
                >
                  Active Listings
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link btn ${this.checkLink("settings") ? "btn-primary" : "btn-white" }`}
                  to="/settings"
                >
                  Settings
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link btn btn-danger"
                  href="#"
                  onClick={e => {
                    let { listings, currentListing, filteredAssets } = this.props.ListingStore;
                    listings = currentListing = filteredAssets = null;

                    e.preventDefault();
                    AuthStore.logout().then(r => {
                      NavigationStore.goToPage("/auth");
                    });
                  }}
                >
                  Logout
                </a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}

	renderUserMenu = () => {
		const { user } = this.props.UserStore;

		if (!user) {
			return <li />;
		}

		return (
			<li className={`nav-item dropdown ${this.state.userMenu ? "show" : ""}`}>
				<a className="nav-link dropdown-toggle" 
					href="#"
					onClick={this.toggleUserMenu}
				>
					{ user.name }
				</a>
				<div className={`dropdown-menu ${this.state.userMenu ? "show" : ""}`}>
					<Link className="dropdown-item" to="/user/profile">
						Profile
					</Link>
					<a className="dropdown-item" href="/logout">
						Log out
					</a>
				</div>
			</li>
		);
  }
  
  checkLink = (str) => {
    const { location } = this.props;
    return location.pathname ? location.pathname.indexOf(str) != -1 : false;
  }

	toggleUserMenu = (e, state = null) => {
		e.stopPropagation();

		this.setState({
			userMenu: typeof state === "boolean" ? state : !this.state.userMenu
		});
	}
}