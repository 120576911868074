import { autorun } from "mobx";
import data from "../data/Routing.json";

class NavigationStore {    
  constructor() {
    this.routing = data.routing;
  }

  goToPage = (destination) => {
    window.appHistory.push(destination);
  }
}

const store = window.NavigationStore = new NavigationStore;

export default store;

autorun(() => {
    console.log(store);
});