import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import LazyRoute from "lazy-route";
import Header from "./Header";
import '../assets/scss/main.scss';
import enplug from "../../vendor/js/enplug.sdk.min.js";

@inject('AuthStore', 'ListingStore', 'NavigationStore', 'UserStore')
@withRouter
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { AuthStore, NavigationStore } = this.props;
    AuthStore.checkToken();

    if (AuthStore.token) {
      NavigationStore.goToPage("/available-listings");
    } else {
      NavigationStore.goToPage("/auth");
      window.enplug.dashboard.pageLoading(false);
    }
  }

  render() {
    const { AuthStore, NavigationStore } = this.props;
    const result = NavigationStore.routing.map(this.renderRoute);

    return (
      <div className="main">
        { AuthStore.token ? <Header /> : null }
        { result }
      </div>
    );
  }

  renderRoute = (route) => {
    const component = route.componentPath ? import('' + route.componentPath) : import('./pages/Page404'),
      exact = route.exact ? route.exact : false;

    return (
      <Route exact={exact} 
        path={route.path}
        render={props => (
          <LazyRoute {...props} 
            component={component} 
          />
        )} 
      /> 
    );
  }
}

export default App;
